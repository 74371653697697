import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Checkbox from "./check-box";

interface ChecklistItem {
  label: string;
  completed: boolean;
}

interface OnboardingChecklistProps {
  items: ChecklistItem[];
}

const OnboardingChecklist: React.FC<OnboardingChecklistProps> = ({ items }) => {
  
  const [checked, setChecked] = useState(false);
  
  const variants = {
      initial: {
        width: 0,
        height: 2,
        x: -100,
        transition: {
          duration: 0.5,
        },
      },
      animate: {
        width: "100%",
        height: 2,
        x: 0,
        transition: {
          duration: 0.5,
        },
      },
      exit: {
        width: 0,
        height: 0,
        x: -100,
        transition: {
          duration: 0.5,
        },
      },
    };

  return (
    <div
      style={{
        width: "20%",
        backgroundColor: "#0E1234", // Dark background for sidebar
        padding: "20px",
        color: "white",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h2 style={{ marginBottom: "20px", borderBottom: "2px solid #EC572E" }}>
        Onboarding Checklist
      </h2>
      <ul
        style={{
          listStyleType: "none",
          padding: 0,
          margin: 0,
        }}
      >
        {items.map((item, index) => (
          <li
            key={index}
            style={{
              padding: "10px 0",
              borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>{item.label}</span>
            <span
              style={{
                width: "20px",
                height: "20px",
                borderRadius: "50%",
                backgroundColor: item.completed ? "#28a745" : "#EC572E", // Green if completed, orange if not
                color: "white",
              }}
            >
              {item.completed ? "✔" : ""}
            </span>
          </li>
        ))}
      </ul>

      <div>

        New Component
        <div className="h-full center w-full">
      <motion.div className="bg-muted w-60 items-center rounded-lg flex p-5 ">
        <Checkbox isChecked={checked} setIsChecked={setChecked} />
        <div className="px-5 overflow-hidden">
          <div className="relative">
            <span className="cursor-pointer" onClick={()=>setChecked(!checked)}>Post on Linkedin</span>
            <AnimatePresence>
              {checked && (
                <motion.span
                  variants={variants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  className="absolute left-0 bg-white w-full top-0 bottom-0 my-auto rounded-full pointer-events-none
                "
                />
              )}
            </AnimatePresence>
          </div>
        </div>
      </motion.div>
    </div>
      </div>
    </div>
  );
};

export default OnboardingChecklist;
